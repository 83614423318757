/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Elements
import TitleDefault from 'components/elements/TitleDefault'

// SVG
import SubTitle from 'components/elements/SubTitle'
import ButtonOutline from 'components/elements/ButtonOutline'

const PackageContainer = styled.div``

const PackagesWrapper = styled(motion.div)``

const Package = styled(CustomLink)`
  min-height: 210px;
  height: 100%;
  width: 100%;
  position: relative;
  border-top-left-radius: 15px;

  &:hover {
    .read-more:after {
      padding-left: 20px;
    }
  }

  > div {
    border-top-left-radius: 15px;
  }

  @media (max-width: 575px) {
    width: 95%;
    height: 205px;
  }
`

const PackageWrapper = styled(motion.div)`
  & div {
    background-color: ${(props) => props.theme.color.face.light};
    border-top-left-radius: 30px;
  }
`

const InnerPackageWrapper = styled.div`
  height: 60%;

  border-top-left-radius: 15px;

  & h3 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['30']};
    font-weight: ${(props) => props.theme.font.weight.l};
    text-transform: uppercase;
    color: #858585;
    margin-bottom: 0;
  }
`

const Content = styled(ParseContent)`
  height: 30%;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size.base};
  }
  & p {
    font-family: ${(props) => props.theme.font.family.title};
    font-size: 25px;
    color: #858585;
    font-weight: 500;
    margin-bottom: 0.3rem;
  }
`

const ReadMore = styled.span`
  font-family: ${(props) => props.theme.font.family.title};
  font-size: 25px;
  color: #858585;
  font-weight: 500;
`

const HoverImage = styled(Plaatjie)`
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  & img {
    border-top-left-radius: 15px;
  }
`

interface PackagesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Packages
  location?: any
}

const Packages: React.FC<PackagesProps> = ({ fields, location }) => {
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null)

  return (
    <section className="py-5 bg-mainDark" id={fields?.anchorId || ''}>
      <PackageContainer className="container py-lg-5">
        <TitleDefault isLight>{fields.description}</TitleDefault>
        <SubTitle isLight>{fields.subtitle}</SubTitle>
        <PackagesWrapper className="row justify-content-between mt-5 mb-4 mb-md-0">
          {fields?.package?.map((edge, index) => {
            const isHover = index === currentIndex

            return (
              <PackageWrapper
                className="col-xl-4 col-md-4 col-sm-6 d-flex align-items-center justify-content-center mb-3 mb-lg-5"
                key={index}
                onHoverStart={() => setCurrentIndex(index)}
                onHoverEnd={() => setCurrentIndex(null)}
              >
                <Package to={edge?.link?.url || ''}>
                  <div className="h-100 d-flex align-items-center">
                    <InnerPackageWrapper className="h-100 w-100 p-4 d-flex flex-column justify-content-between">
                      <div>
                        <div className="d-flex flex-wrap align-items-center">
                          <h3 className="mb-1">{edge?.title}</h3>
                          {edge?.mostChosen && (
                            <span className="pl-3 pl-sm-0 ml-xl-3 py-2 py-xl-0 text-contrast font-size-base">
                              Meestgekozen
                            </span>
                          )}
                        </div>
                        <Content content={edge?.description} />
                      </div>
                      <ReadMore className="read-more d-flex align-items-center justify-content-between">
                        <span>{edge?.link?.title}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="99"
                          height="8"
                          viewBox="0 0 99 8"
                          fill="none"
                        >
                          <path
                            d="M98.3536 4.35356C98.5488 4.1583 98.5488 3.84172 98.3536 3.64646L95.1716 0.464474C94.9763 0.269212 94.6597 0.269212 94.4645 0.464474C94.2692 0.659737 94.2692 0.976319 94.4645 1.17158L97.2929 4.00001L94.4645 6.82844C94.2692 7.0237 94.2692 7.34028 94.4645 7.53554C94.6597 7.7308 94.9763 7.7308 95.1716 7.53554L98.3536 4.35356ZM-4.37114e-08 4.5L98 4.50001L98 3.50001L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="#FF6700"
                          />
                        </svg>
                      </ReadMore>
                    </InnerPackageWrapper>
                  </div>
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHover ? 1 : 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <HoverImage
                        image={edge?.hoverImage}
                        alt={edge?.title || ''}
                      />
                    </motion.div>
                  </AnimatePresence>
                </Package>
              </PackageWrapper>
            )
          })}
        </PackagesWrapper>
        {fields?.link && location.pathname === '/' && (
          <ButtonOutline to={fields.link.url || ''} className="mx-auto">
            {fields.link.title}
          </ButtonOutline>
        )}
      </PackageContainer>
    </section>
  )
}

export default Packages
